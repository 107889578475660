import { ref, watch, computed } from "vue";
import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import { usePreferences } from "@/services/preferences";

export const useCurrentUser = defineStore("user", () => {
  const data = ref<UserProfile | null>(null);
  const subscriptions = ref<Subscription[]>([]);

  const { updatePreferences } = usePreferences();

  const preferences = useStorage<Preferences>("preferences", Object);
  const area = useStorage<CourseArea | null>("tripArea", Object);

  const currentCourse = useStorage<Course | CourseComplete | null>(
    "tripCourse",
    Object
  );

  const currentModule = useStorage<LessonModule | null>("tripModule", Object);
  const currentTopic = useStorage<CourseTopic | null>("tripTopic", Object);

  const dictionaryModulesVisits = useStorage<any>("registerModules", Object);
  const dictionaryTopicsVisits = useStorage<any>("registerTopics", Object);

  const fullName = computed(
    () => data.value?.first_name + " " + data.value?.last_name
  );

  const platformMode = computed(
    () => preferences.value.platform_mode || "trip"
  );

  const category = computed(() => preferences.value.learning_path);
  const profileAvatar = computed(() => data.value?.gravatar);

  const configured = computed(() => {
    if (platformMode.value === "trip") {
      return (
        !!preferences.value.learning_path && !!preferences.value.current_course
      );
    }

    return !!platformMode.value;
  });

  const hasActivePlan = computed(() => {
    return (
      !!data.value?.role ||
      subscriptions.value.some(
        (subscription) => subscription.status === "active"
      )
    );
  });

  watch(
    () => preferences.value,
    (newValue, oldValue) => {
      const payload = (newValue || {}) as any;

      const hasMutation = Object.entries(oldValue).some(([key, value]) => {
        return payload[key] !== value;
      });

      if (hasMutation) {
        preferences.value = Object.assign({}, preferences.value, payload);

        updatePreferences(payload);
      }
    }
  );

  function setSubscriptions(subs: Subscription[]) {
    subscriptions.value = subs;
  }

  function setPlatformMode(mode: "trip" | "dashboard") {
    preferences.value = {
      ...preferences.value,
      platform_mode: mode,
    };
  }

  function setArea(areaSlug: CourseArea) {
    area.value = areaSlug;
  }

  function setCategory(tag: string) {
    preferences.value = {
      ...preferences.value,
      learning_path: tag,
    };
  }

  function setCourse(course: Course | CourseComplete | null) {
    preferences.value = {
      ...preferences.value,
      first_course: preferences.value.first_course || (course?.slug as string),
      current_course: course?.slug as string,
    };

    currentCourse.value = course;
  }

  function setCurrentModule(module: LessonModule | null) {
    currentModule.value = module;
  }

  function setCurrentTopic(module: CourseTopic | null) {
    currentTopic.value = module;
  }

  function registerTopicsVisits(moduleSlug: string, topicSlug: string) {
    dictionaryTopicsVisits.value[moduleSlug] = topicSlug;
  }

  function registerModulesVisits(
    courseSlug: string,
    lessonModule: LessonModule
  ) {
    dictionaryModulesVisits.value[courseSlug] = {
      slug: lessonModule.slug,
      position: lessonModule.position,
    };
  }

  return {
    data,
    subscriptions,
    fullName,
    configured,
    profileAvatar,
    preferences,
    area: area,
    category: category,
    platformMode: platformMode,
    currentCourse: currentCourse,
    currentModule: currentModule,
    currentTopic: currentTopic,
    dictionaryTopicsVisits: dictionaryTopicsVisits,
    dictionaryModulesVisits: dictionaryModulesVisits,
    setPlatformMode,
    setArea,
    setCategory,
    setCourse,
    setCurrentModule,
    setCurrentTopic,
    setSubscriptions,
    registerTopicsVisits,
    registerModulesVisits,
    hasActivePlan,
  };
});
